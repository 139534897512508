var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "section-description section-additional-description box-decorated-edges-mini mt-5",
    },
    [
      _c("h3", [_vm._v('The "Enhancement to a real item" spell')]),
      _vm._v(
        " Holders of this Wizarre Enhancement can burn it in exchange for a Uniqly NFT, which in turn allows the owner to claim our unique physical T-shirt with Proof of Ownership made by the magical technology of "
      ),
      _c(
        "a",
        {
          staticClass: "text--secondary text-decoration-underline",
          attrs: { target: "_blank", href: "https://uniqly.io" },
        },
        [_vm._v(" Uniqly.io ")]
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        " The Uniqly's token is minted on Polygon. However, the redeeming currently supports MetaMask only. Please, make sure you "
      ),
      _c(
        "a",
        {
          staticClass: "text--secondary text-decoration-underline",
          attrs: {
            target: "_blank",
            href: "https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask",
          },
        },
        [_vm._v(" added the Polygon network into your MetaMask ")]
      ),
      _vm._v(' before you cast the "Enhancement to a real item" spell. '),
      _c("br"),
      _c("br"),
      _vm._m(0),
      _c("br"),
      _c("br"),
      _c("b", [_vm._v("Now, it's time for magic:")]),
      _vm._m(1),
      _c("br"),
      _vm._v(" Congratulations, you've just cast the strongest spell! "),
      _c("br"),
      _vm._v(
        " In case any questions occurred, please contact info@wizarre.io for support "
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-row text-right justify-end mt-10" },
        [
          _c(
            "main-button",
            {
              attrs: {
                disabled:
                  _vm.isRedeemApproved ||
                  !_vm.userHasSome ||
                  !_vm.isWalletConnected ||
                  _vm.claimedReward,
                loading: _vm.redeemInProgress,
                "on-click": _vm.approveRedeemTShirt,
              },
            },
            [_vm._v(" Approve ")]
          ),
          _c(
            "main-button",
            {
              attrs: {
                disabled:
                  !_vm.isRedeemApproved ||
                  !_vm.userHasSome ||
                  !_vm.isWalletConnected ||
                  _vm.claimedReward,
                loading: _vm.redeemInProgress,
                "on-click": _vm.redeemTShirt,
              },
            },
            [_vm._v(" Burn & whitelist ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("b", [
      _vm._v("Important: "),
      _c("ul", [
        _c("li", [
          _vm._v(
            " This Enhancement will be burned (destroyed) during this process in exchange to whitelisting. "
          ),
        ]),
        _c("li", [
          _vm._v(
            " The whitelisted addresses can claim their Uniqly NFT on the Polygon network, and then redeem them for a physical t-shirt for free. "
          ),
        ]),
        _c("li", [
          _vm._v(
            " Due to technical limitations, it's possible to claim once per address. If you have more t-shirts, send them and claim them from other wallet addresses. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          " Claim the T-shirt Enhancement on the Shrine of Masquerade on the Wizarre Desert staking map (the very last shrine on the map.) Be fast, as it's a limited edition! "
        ),
      ]),
      _c("li", [
        _vm._v(
          ' The magical sewing machines of Uniqly.io support only MetaMask wallets for redeeming. If you use any other Web3 wallet, you can transfer the T-shirt Enhancement to your MetaMask. For that, click the "Transfer" button above this instruction. '
        ),
      ]),
      _c("li", [_vm._v(' Click "Approve" button below this instruction ')]),
      _c("li", [
        _vm._v(
          ' Once the transaction went through, click the "Burn and whitelist" button below this instruction. Your Enhancement will be burned, and your wallet address will get into the whitelisting process. It may take up to 2 days. '
        ),
      ]),
      _c("li", [
        _vm._v(" Go to "),
        _c(
          "a",
          {
            staticClass: "text--secondary text-decoration-underline",
            attrs: {
              target: "_blank",
              href: "https://www.uniqly.io/stores/wizarre",
            },
          },
          [_vm._v("the Wizarre store on Uniqly.io")]
        ),
        _vm._v(
          " . Connect the MetaMask wallet you used earlier, then choose the model of the T-shirt you want and claim it. "
        ),
      ]),
      _c("li", [
        _vm._v(
          " After the transaction goes through, you will get a T-shirt NFT by Uniqly, which you can trade or redeem for a physical item available for shipping worldwide. You can find the redeeming instruction on Uniqly's website or on "
        ),
        _c(
          "a",
          {
            staticClass: "text--secondary text-decoration-underline",
            attrs: {
              target: "_blank",
              href: "https://www.youtube.com/watch?v=MYLB_z3-FWU",
            },
          },
          [_vm._v("Youtube")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }