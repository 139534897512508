<template>
    <div class="section-description section-additional-description box-decorated-edges-mini mt-5">
        <h3>The "Enhancement to a real item" spell</h3>
        Holders of this Wizarre Enhancement can burn it in exchange for a Uniqly NFT, which in turn
        allows the owner to claim our unique physical T-shirt with Proof of Ownership made by the
        magical technology of
        <a class="text--secondary text-decoration-underline"
           target="_blank"
           href="https://uniqly.io"
        >
            Uniqly.io
        </a>
        <br/><br/>
        The Uniqly's token is minted on Polygon. However, the redeeming currently supports MetaMask
        only.

        Please, make sure you
        <a class="text--secondary text-decoration-underline"
           target="_blank"
           href="https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask"
        >
            added the Polygon network into your MetaMask
        </a>
        before you cast the "Enhancement to a real item" spell.
        <br/>
        <br/>
        <b>Important:
            <ul>
                <li>
                    This Enhancement will be burned (destroyed) during this process in exchange to
                    whitelisting.
                </li>
                <li>
                    The whitelisted addresses can claim their Uniqly NFT on the Polygon network, and then
                    redeem them for a physical t-shirt for free.
                </li>
                <li>
                    Due to technical limitations, it's possible to claim once per address. If you have more t-shirts,
                    send them and claim them from other wallet addresses.
                </li>
            </ul>
        </b>

        <br/><br/>
        <b>Now, it's time for magic:</b>
        <ol>
            <li>
                Claim the T-shirt Enhancement on the Shrine of Masquerade on the Wizarre Desert
                staking map (the very last shrine on the map.) Be fast, as it's a limited edition!
            </li>
            <li>
                The magical sewing machines of Uniqly.io support only MetaMask wallets for
                redeeming. If you use any other Web3 wallet, you can transfer the T-shirt
                Enhancement to your MetaMask. For that, click the "Transfer" button above this
                instruction.
            </li>
            <li>
                Click "Approve" button below this instruction
            </li>
            <li>
                Once the transaction went through, click the "Burn and whitelist" button
                below this instruction. Your Enhancement will be burned, and your wallet address
                will get into the whitelisting process. It may take up to 2 days.
            </li>
            <li>
                Go to
                <a class="text--secondary text-decoration-underline" target="_blank"
                   href="https://www.uniqly.io/stores/wizarre">the Wizarre store on Uniqly.io</a>
                . Connect the MetaMask wallet you used earlier, then choose the model of the T-shirt
                you want and claim it.
            </li>
            <li>
                After the transaction goes through, you will get a T-shirt NFT by Uniqly, which you
                can trade or redeem for a physical item available for shipping worldwide. You can
                find the redeeming instruction on Uniqly's website or on
                <a class="text--secondary text-decoration-underline" target="_blank"
                   href="https://www.youtube.com/watch?v=MYLB_z3-FWU">Youtube</a>
            </li>
        </ol>

        <br/>
        Congratulations, you've just cast the strongest spell!
        <br/>
        In case any questions occurred, please contact info@wizarre.io for support

        <div class="d-flex flex-row text-right justify-end mt-10">
            <main-button
                :disabled="isRedeemApproved || !userHasSome || !isWalletConnected || claimedReward"
                :loading="redeemInProgress"
                :on-click="approveRedeemTShirt"
            >
                Approve
            </main-button>
            <main-button
                :disabled="!isRedeemApproved || !userHasSome || !isWalletConnected || claimedReward"
                :loading="redeemInProgress"
                :on-click="redeemTShirt"
            >
                Burn & whitelist
            </main-button>
        </div>
    </div>
</template>

<script>

import MainButton from "../../ui/main-button";
import EnhancementWhitelistContract from "../../../services/contracts/enhancementWhitelistContract";

export default {
    components: {MainButton},
    props: {
        amount: Number,
    },
    data() {
        return {
            redeemInProgress: false,
            isRedeemApproved: false,
            enhancementWhitelistContract: null,
            claimedReward: false,
        }
    },
    mounted() {
        if (this.isWalletConnected) {
            this.initContract();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.initContract();
            });
        }
    },
    computed: {
        userHasSome() {
            return this.amount > 0;
        },
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
    },
    methods: {
        async initContract() {
            this.enhancementWhitelistContract = new EnhancementWhitelistContract(this.web3);
            if (await this.enhancementWhitelistContract.isApproved()) {
                this.isRedeemApproved = true;
            }
            this.checkIsClaimed().then();
        },
        async checkIsClaimed() {
            this.claimedReward = await this.enhancementWhitelistContract.isClaimedFor(this.web3.address);
        },
        async approveRedeemTShirt() {
            this.redeemInProgress = true;
            await this.enhancementWhitelistContract.approve();
            this.isRedeemApproved = true;
            this.redeemInProgress = false;
        },
        async redeemTShirt() {
            this.redeemInProgress = true;
            await this.enhancementWhitelistContract.burnEnhancementToWhitelist();
            this.redeemInProgress = false;
            this.refreshNftAmount();
            this.checkIsClaimed().then();

            this.flashMessage.show({
                status: 'success',
                message: 'Your wallet address has been added to whitelisting process'
            });
        },
        refreshNftAmount() {
            this.$emit('refreshNftAmount');
        }
    },
};
</script>

<style lang="scss" scoped>
.section-additional-description {
    padding-top: 10px;
    padding-bottom: 20px;

    .main-button {
        width: 25%;
        margin-left: 10px;
    }

    li {
        margin: 7px 0;
    }
}

@media only screen and (max-width: $sm) {
    .section-additional-description {
        .main-button {
            width: 50%;
        }
    }
}
</style>
