<template>
    <div v-if="itemIdSpecified" class="full-enhancement">
        <v-row>
            <v-col cols="10" lg="3" md="4" offset="1" offset-lg="1" offset-md="0" offset-sm="3" offset-xl="2" sm="6"
                   xl="4">
                <div class="section-img-frame">
                    <img class="bg" src="/img/enhancement/frame-img.svg"/>
                    <div class="e-title">
                        <span>
                            {{ item.name }}
                        </span>
                    </div>
                    <div class="e-amount">
                        <v-progress-circular
                            v-if="loadingAmount"
                            class="points-spinner"
                            indeterminate
                            size="10"
                        ></v-progress-circular>
                        <div v-if="!loadingAmount">
                            <span v-if="userHasSome">
                                {{ amount }}x
                            </span>
                            <span v-else-if="isOfferSelected">
                                {{ activeOffer.amount }}x
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <img :src="item.thumbnail_300 || item.image" class="item"/>
                </div>
            </v-col>

            <v-col cols="10" lg="7" md="8" offset="1" offset-md="0" offset-sm="2" sm="8" xl="6">
                <div
                    v-if="isOfferActive"
                    class="box-decorated-edges-mini common-bg section-buttons"
                >
                    <asset-take-offer-popup
                        :activeOffer="activeOffer"
                    />
                </div>
                <v-row v-if="isOwner && !isOfferActive"
                       class="box-decorated-edges-mini common-bg section-buttons"
                       dense
                >
                    <v-col v-if="ownAnything" cols="6">
                        <enhancement-transfer-popup
                            :amount="amount"
                            :nft="item"
                        />
                    </v-col>
                    <v-col v-if="ownAnything" cols="6">
                        <asset-create-offer-popup
                            :assetDTO="enhancementDTO"
                            :assetType="assetType"
                            :max-amount="amount"
                        />
                    </v-col>
                </v-row>
                <div class="section-description common-bg box-decorated-edges-mini">
                    <div v-if="item.effect" class="effect-description mb-5">
                        {{ item.effect }}
                    </div>

                    <div class="whole-description common-bg">
                        {{ item.description }}
                    </div>
                </div>

                <t-shirt-burn
                    v-if="isItTShirt"
                    :amount="amount"
                    @refreshNftAmount="refreshNftAmount"
                ></t-shirt-burn>
            </v-col>

        </v-row>
    </div>
</template>
<script>
import EnhancementTransferPopup from "./enhancement-transfer-popup"
import TShirtBurn from "./t-shirt-burn"
import OfferDTO from "@/classes/market/OfferDTO";
import AssetTakeOfferPopup from "@/components/market/asset-take-offer-popup";
import AssetDTO from "@/classes/asset/AssetDTO";
import {AssetType} from "@/classes/asset/AssetType";
import AssetCreateOfferPopup from "@/components/market/asset-create-offer-popup";
import {OfferStatus} from "@/classes/market/OfferStatus";

export default {
    components: {
        AssetCreateOfferPopup,
        AssetTakeOfferPopup,
        EnhancementTransferPopup,
        TShirtBurn
    },
    props: {
        item: {},
        amount: undefined,
        walletAddress: null,
        activeOffer: {
            type: OfferDTO,
            default: undefined,
        },
    },
    data() {
        return {
            assetType: AssetType.ENHANCEMENT,

        };
    },
    methods: {
        async refreshNftAmount() {
            this.$emit('refreshNftAmount');
        }
    },
    computed: {
        isOfferActive() {
            return Boolean(this?.activeOffer?.status === OfferStatus.AVAILABLE)
        },
        isItTShirt() {
            return 28 === Number(this.item.id);
        },
        userHasSome() {
            return this.amount > 0;
        },
        loadingAmount() {
            return this.amount === undefined;
        },
        isOfferSelected() {
            return Boolean(this.activeOffer);
        },
        isOwner() {
            return Boolean(
                this.amount
                || (this.isOfferActive && this.isOwnerOfOffer)
            )
        },
        isOwnerOfOffer() {
            return Boolean(
                !!this.walletAddress
                && (this?.walletAddress?.toLowerCase() === this?.activeOffer?.creator?.toLowerCase())
            )
        },
        ownAnything() {
            return Boolean(
                this.amount > 0
            );
        },
        enhancementDTO() {
            return new AssetDTO(this.item);
        },
        itemIdSpecified() {
            const parsedItemId = Number(this.item.id);
            return !isNaN(parsedItemId);
        },
    }
}
</script>
<style lang="scss" scoped>

.common-bg {
    background-color: rgba(42, 28, 51);
}

.section-img-frame {
    width: 90%;
    height: auto;
    margin: auto;
    text-align: center;
    position: relative;


    .bg {
        width: 100%;
        height: auto;
        position: relative;
        display: block;
    }

    .e-title {
        width: 86%;
        height: 13%;
        top: 3.5%;
        left: 7%;
        right: auto;
        margin: auto;
        position: absolute;
        color: #000;
        font-size: 150%;
        line-height: 120%;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .e-amount {
        width: 20%;
        height: 6%;
        bottom: 1%;
        right: 4%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .item {
        top: 30%;
        width: 80%;
        left: 10%;
        right: auto;
        margin: auto;
        position: absolute;
        display: block;
    }
}

::v-deep .section-description {
    padding: 30px 40px;
    border: 3px solid var(--v-frames-base);

    .effect-description {
        font-weight: bold;
    }

    .whole-description {
        color: var(--v-frames-base);
        font-style: italic;
    }
}

@media only screen and (max-width: $lg) {
    .section-img-frame {
        .e-title {
            font-size: 120%;
        }
    }
}

@media only screen and (max-width: $md) {
    ::v-deep .section-description {
        padding: 30px;
    }
    .section-img-frame {
        .e-title {
            font-size: 100%;
        }
    }
}

@media only screen and (max-width: $sm) {
    .section-img-frame {
        .e-title {
            font-size: 140%;
        }

        .e-amount {
            font-size: 120%;
        }
    }
}

@media only screen and (max-width: $xs) {
    .section-img-frame {
        .e-title {
            font-size: 120%;

        }

        .e-amount {
            font-size: 100%;
        }
    }
}


</style>
