<template>
    <div class="d-block w-100">
        <main-button class="open-popup-btn" :disabled="!userHasSome" :on-click="openTransferPopup">
            Transfer
        </main-button>

        <dialog-box
            :model="transferPopupOpen"
            v-on:dialogClosed="transferPopupOpen=!transferPopupOpen"
            title="Item transfer"
            width="500"
        >
            <div class="mt-5">
                <v-row no-gutters>
                    <v-col cols="12" sm="5">
                        <div class="d-flex">
                            <mini-enhancement
                                :item="nft"
                                :amount="selectedAmountToTransfer"
                            >
                            </mini-enhancement>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="7" class="text-center align-center">

                        <br class="d-block d-sm-none"/><br/>

                        <input-amount
                            :id="nft.id"
                            :init-value="1"
                            :max-value="amount"
                            :suffix="'/ '+amount"
                            @valueChanged="transferAmountChanged"
                            label="Amount"
                            class="mt-5 d-inline-block d-sm-block"
                            style="width: 150px;"
                        ></input-amount>

                        <br class="d-none d-sm-block"/><br/>

                        <v-form v-model="isTransferFormValid">
                            <v-text-field
                                class="d-inline-block d-sm-block w-100"
                                v-model="transferRecipientAddress"
                                :rules="[v => v.length === 42 || 'Address has 42 characters']"
                                counter="42"
                                label="Recipient address"
                                color="#ccc"
                            ></v-text-field>

                            <main-button
                                class="mt-5 pull-right"
                                :disabled="!isWalletConnected || !selectedAmountToTransfer || 0===selectedAmountToTransfer  || !isTransferFormValid"
                                :loading="transferInProgress"
                                :on-click="()=>transferClick(nft.id, selectedAmountToTransfer, transferRecipientAddress)"
                            >
                                Transfer
                            </main-button>
                        </v-form>
                    </v-col>
                </v-row>
            </div>
        </dialog-box>
    </div>
</template>

<script>

import MainButton from "../../ui/main-button";
import EnhancementContract from "../../../services/contracts/enhancementContract";
import DialogBox from "../../dialog-box";
import MiniEnhancement from "./mini-enhancement";
import InputAmount from "../../input-amount";

export default {
    components: {InputAmount, MiniEnhancement, DialogBox, MainButton},
    props: {
        nft: Object,
        amount: Number,
    },
    data() {
        return {
            transferInProgress: false,
            transferPopupOpen: false,
            selectedAmountToTransfer: 0,
            transferRecipientAddress: '',
            isTransferFormValid: false,
        }
    },
    mounted() {

    },
    computed: {
        userHasSome() {
            return this.amount > 0;
        },
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
    },
    methods: {
        transferAmountChanged(obj) {
            this.selectedAmountToTransfer = parseInt(obj.value);
        },
        openTransferPopup() {
            this.transferPopupOpen = true;
        },
        async transferClick(id, amount, recipient) {
            this.transferInProgress = true;
            const enhancementContract = new EnhancementContract(this.web3);
            enhancementContract.transfer(id, amount, recipient)
                .then((res) => {
                    this.flashMessage.show({
                        status: 'success',
                        message: 'Item transfer completed'
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Item transfer failed'
                    });
                })
                .finally(() => {
                    this.transferPopupOpen = false;
                    this.transferInProgress = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.open-popup-btn.main-button {
}
</style>
