var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.itemIdSpecified
    ? _c(
        "div",
        { staticClass: "full-enhancement" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "10",
                    lg: "3",
                    md: "4",
                    offset: "1",
                    "offset-lg": "1",
                    "offset-md": "0",
                    "offset-sm": "3",
                    "offset-xl": "2",
                    sm: "6",
                    xl: "4",
                  },
                },
                [
                  _c("div", { staticClass: "section-img-frame" }, [
                    _c("img", {
                      staticClass: "bg",
                      attrs: { src: "/img/enhancement/frame-img.svg" },
                    }),
                    _c("div", { staticClass: "e-title" }, [
                      _c("span", [_vm._v(" " + _vm._s(_vm.item.name) + " ")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "e-amount" },
                      [
                        _vm.loadingAmount
                          ? _c("v-progress-circular", {
                              staticClass: "points-spinner",
                              attrs: { indeterminate: "", size: "10" },
                            })
                          : _vm._e(),
                        !_vm.loadingAmount
                          ? _c("div", [
                              _vm.userHasSome
                                ? _c("span", [
                                    _vm._v(" " + _vm._s(_vm.amount) + "x "),
                                  ])
                                : _vm.isOfferSelected
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.activeOffer.amount) +
                                        "x "
                                    ),
                                  ])
                                : _c("span", [_vm._v("-")]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("img", {
                      staticClass: "item",
                      attrs: { src: _vm.item.thumbnail_300 || _vm.item.image },
                    }),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "10",
                    lg: "7",
                    md: "8",
                    offset: "1",
                    "offset-md": "0",
                    "offset-sm": "2",
                    sm: "8",
                    xl: "6",
                  },
                },
                [
                  _vm.isOfferActive
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "box-decorated-edges-mini common-bg section-buttons",
                        },
                        [
                          _c("asset-take-offer-popup", {
                            attrs: { activeOffer: _vm.activeOffer },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isOwner && !_vm.isOfferActive
                    ? _c(
                        "v-row",
                        {
                          staticClass:
                            "box-decorated-edges-mini common-bg section-buttons",
                          attrs: { dense: "" },
                        },
                        [
                          _vm.ownAnything
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("enhancement-transfer-popup", {
                                    attrs: {
                                      amount: _vm.amount,
                                      nft: _vm.item,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ownAnything
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("asset-create-offer-popup", {
                                    attrs: {
                                      assetDTO: _vm.enhancementDTO,
                                      assetType: _vm.assetType,
                                      "max-amount": _vm.amount,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "section-description common-bg box-decorated-edges-mini",
                    },
                    [
                      _vm.item.effect
                        ? _c(
                            "div",
                            { staticClass: "effect-description mb-5" },
                            [_vm._v(" " + _vm._s(_vm.item.effect) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "whole-description common-bg" },
                        [_vm._v(" " + _vm._s(_vm.item.description) + " ")]
                      ),
                    ]
                  ),
                  _vm.isItTShirt
                    ? _c("t-shirt-burn", {
                        attrs: { amount: _vm.amount },
                        on: { refreshNftAmount: _vm.refreshNftAmount },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }